<template>
  <div class="container-fluid">
    <div class="row mb-5">
      <span class="display-4 col-12">{{ unitOfMeasure.name }}</span>
    </div>

    <div class="row">
      <div class="col-12">
        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.NAME") }}</dt>
          <dd class="col-sm-8">
            {{ unitOfMeasure.name }}
          </dd>
        </dl>
        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.CREATED_AT") }}</dt>
          <dd class="col-sm-8" v-if="unitOfMeasure.created_at">
            {{ $formatDate(unitOfMeasure.created_at) }}
          </dd>
        </dl>
        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.UPDATED_AT") }}</dt>
          <dd class="col-sm-8" v-if="unitOfMeasure.updated_at">
            {{ $formatDate(unitOfMeasure.updated_at) }}
          </dd>
        </dl>
        <dl
          class="row"
          v-if="$currentUserCan($permissions.PERM_VIEW_ANY_ORGANIZATIONS)"
        >
          <dt class="col-sm-4">{{ $t("COMMON.ORGANIZATION") }}</dt>
          <dd class="col-sm-8">
            <organization :organization="unitOfMeasure.organization" />
          </dd>
        </dl>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <unit-of-measure-view-global-units :unitOfMeasure="unitOfMeasure" />
      </div>
    </div>
  </div>
</template>

<script>
import UnitOfMeasureViewGlobalUnits from "./UnitOfMeasureViewGlobalUnits.vue";
export default {
  name: "unit-of-measure-view-global",

  components: { UnitOfMeasureViewGlobalUnits },

  props: ["unitOfMeasure"],

  data() {
    return {};
  },

  computed: {},

  created() {},

  methods: {},

  mounted() {},

  watch: {
    unitOfMeasure(unitOfMeasure) {},
  },
};
</script>
